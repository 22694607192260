import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import { useStore } from "vuex";
import { computed } from "vue";
import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'DarkModeComponentMobile',
  setup(__props) {

const store = useStore();
const isDarkMode = computed(() => store.state.darkMode);

library.add(faMoon as IconDefinition);
library.add(faSun as IconDefinition);

const toggleDarkMode = async () => {
  await store.dispatch("toggleDarkMode");
  if (store.state.isDarkMode) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "inline-block h-5 border-l-2 px-4 border-gray-100 text-sm cursor-pointer",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleDarkMode()))
  }, [
    (!isDarkMode.value)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: ['fa', 'moon']
        }))
      : _createCommentVNode("", true),
    (isDarkMode.value)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: ['fa', 'sun'],
          class: "text-yellow-400"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})