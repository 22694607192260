
import { Options, Vue } from "vue-class-component";
import CookieModal from "@/components/CookieModal.vue";
import DarkModeComponent from "@/components/DarkModeComponent.vue";
import DarkModeComponentMobile from "@/components/DarkModeComponentMobile.vue";

@Options({
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        document.title = "Pooyan - " + this.currentRouteName || "Pooyan";
      },
    },
  },
  components: {
    DarkModeComponentMobile,
    DarkModeComponent,
    CookieModal,
  },
})
export default class App extends Vue {}
